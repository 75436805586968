<template>
  <Layout tituloPagina="Sistema | Certificado de licencia de software">
    <div class="row">
      <div class="col-lg-12">
        <cmp-secciones seccion="certificado-licencia"></cmp-secciones>
      </div>
    </div>
    <br />

    <div class="card">
      <div class="card-body">
        <iframe :src="API+'/sistema/certificado-licencia?id='+idCertificadoLicencia" frameborder="0"
          style="width: 100%; height: 600px"
          v-if="idCertificadoLicencia != null"
        >
        </iframe>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import API from '@/API.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import CmpSecciones from './CmpSecciones.vue'
import moment from 'moment'
export default {
  name: 'CertificadoLicencia',
  components: { Layout, CmpSecciones },
  data() {
    return {
      API: API,
      idCertificadoLicencia: null,
    }
  },

  created: function() {
    var self = this
    SistemaSrv.idCertificadoLicencia().then(response => {
      self.idCertificadoLicencia = response.data
    })
  },
  methods: {
    atributoVacio: function(atributo) {
      if (atributo == '' || atributo == null || atributo == undefined)
        return true
      return false
    }
  }
}
</script>

<style scoped>

</style>